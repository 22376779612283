import { render, staticRenderFns } from "./collapsible-with-items-editable.vue?vue&type=template&id=13042f1a&scoped=true"
import script from "./collapsible-with-items-editable.vue?vue&type=script&lang=js"
export * from "./collapsible-with-items-editable.vue?vue&type=script&lang=js"
import style0 from "./collapsible-with-items-editable.vue?vue&type=style&index=0&id=13042f1a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13042f1a",
  null
  
)

export default component.exports