<template>
  <div
    class="p-2 mt-4 rounded border d-flex justify-content-center"
    v-bind:style="{ backgroundColor: color }"
  >
    <span v-html="this.params.value"></span>
  </div>
</template>
<script>
export default {
  data() {
    return {
      color: null,
    };
  },
  beforeMount() {
    this.color = this.params.color || 'white';
  },
};
</script>
