<template>
  <div>
    <div v-if="params.value !== '(Select All)'" class="d-flex">
      <span v-html="html" class="mr-1"></span>
      {{ options.find(item => item.value === status)?.label }}
    </div>
    <div v-else>(Select All)</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status: null,
      html: null,
      options: [
        {
          label: 'Nur SKR Inlandsflüge',
          value: 'NurSkr',
        },
        {
          label: 'Nur DMC Inlandsflüge',
          value: 'NurDmc',
        },
        {
          label: 'Gemischte Inlandsflüge',
          value: 'Gemischt',
        },
        {
          label: 'Kein Inlandsflug',
          value: 'KeinInlandsflug',
        },
      ],
    };
  },
  mounted() {
    if (this.params && this.params.value) {
      this.status = this.params.value;
      if (this.status === 'NurSkr') {
        this.html = '<i class="fas fa-plane text-danger"></i>';
      } else if (this.status === 'NurDmc') {
        this.html = '<i class="fas fa-plane" style="color: #000"></i>';
      } else if (this.status === 'Gemischt') {
        this.html = '<i class="fas fa-plane" style="color: #A6A6A8"></i>';
      } else if (this.status === 'KeinInlandsflug') {
        this.html = '';
      }
    }
  },
  methods: {},
};
</script>
