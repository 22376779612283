<template>
  <div>
    <Multiselect
      :value="selectedFlughaefen"
      :loading="isLoading"
      :options="airportsList"
      :selectLabel="''"
      :multiple="isMultipleAllowed"
      :openDirection="openDirection"
      :max="100"
      @search-change="onSearchFlughafen"
      @input="onInput($event)"
      :placeholder="selectedFlughaefen === null ? '---' : $t('FLUEGE.FLUGHAFENWAEHLEN')"
      label="iataCode"
      track-by="iataCode"
      :style="cssProps"
      :disabled="disabled"
    >
      <template #tag="{ option, remove }"> </template>
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapState } from 'vuex';

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      searchFilter: null,
    };
  },
  props: {
    isMultipleAllowed: { type: Boolean, default: false },
    value: { type: [String, Array] },
    filterCountry: { type: String, default: null },
    placeholder: { type: String },
    openDirection: { type: String, default: '' },
    isSidebarTerminplanung: { type: Boolean, default: false },
    isChangedMetadata: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    iataWithName({ iataCode, name, country, city }) {
      return `${iataCode}`;
    },
    onInput($event) {
      if (this.isMultipleAllowed) {
        const iataCodes =
          Array.isArray($event) && $event.length > 0 ? $event.map(airport => airport.iataCode) : [];
        this.$emit('input', iataCodes);
      } else {
        this.$emit('input', $event?.iataCode);
      }
    },
    onSearchFlughafen($event) {
      this.searchFilter = $event;
    },
  },
  computed: {
    ...mapState({
      flughaefen: state => state.flughaefen.flughaefen,
      isLoading: state => state.flughaefen.isLoading,
    }),
    selectedFlughaefen() {
      if (!this.value || !this.flughaefen) {
        return null;
      }
      if (this.isMultipleAllowed) {
        return this.flughaefen.filter(airport => this.value.includes(airport.iataCode));
      } else {
        return this.flughaefen.filter(airport => this.value === airport.iataCode);
      }
    },
    airportsList() {
      if (this.searchFilter) {
        return this.flughaefen.filter(airport =>
          airport.iataCode.toLowerCase().startsWith(this.searchFilter.toLowerCase())
        );
      } else if (this.filterCountry) {
        const filteredAirports = this.flughaefen.filter(airport => airport.country === this.filterCountry);
        return filteredAirports.length > 0 ? filteredAirports : this.flughaefen;
      } else {
        const countryFilter = this.$route.path.replace('/reisen/', '').substring(0, 2);
        const filteredByCountry = this.flughaefen.filter(airport => airport.country === countryFilter);
        return filteredByCountry.length > 0 ? filteredByCountry : this.flughaefen;
      }
    },
    cssProps() {
      return {
        '--border': this.isSidebarTerminplanung ? 'none' : '1px solid #e8e8e8',
        '--height': this.isSidebarTerminplanung ? '40px' : '',
        '--padding-left': this.isSidebarTerminplanung ? '4px' : '0.5rem',
        '--background-color': this.isChangedMetadata ? '#3699FF50' : this.disabled ? '#F3F6F9' : 'white',
      };
    },
  },
};
</script>
<style scoped>
::v-deep .multiselect--disabled .multiselect__select {
  background: none !important;
}
:deep(.multiselect__tags) {
  height: var(--height);
  border: var(--border);
  background-color: var(--background-color);
}
:deep(.multiselect__placeholder, .multiselect__tags, .multiselect) {
  padding-left: var(--padding-left) !important;
}
</style>
