<template>
  <multiselect
    v-bind="$attrs"
    v-on="$listeners"
    tagPlaceholder="ENTER um ein Tag zu erstellen"
    selectedLabel="Ausgewählt"
    :selectLabel="!isSelectionLabelHidden ? 'ENTER zum Auswählen' : ''"
    :selectGroupLabel="!isSelectionLabelHidden ? 'ENTER um die Gruppe auszuwählen' : ''"
    :deselectLabel="!isSelectionLabelHidden ? 'ENTER zum Abwählen' : ''"
    :deselectGroupLabel="!isSelectionLabelHidden ? 'ENTER um die Gruppe abzuwählen' : ''"
    :placeholder="placeholder || 'Tippen um Suche zu starten'"
  >
    <template #noResult><span>Suche ergab keine Treffer</span></template>
    <template #noOptions><span>Keine Optionen</span></template>
    <template #clear="props">
      <div
        title="Alle zurücksetzen"
        class="multiselect__clear"
        v-if="isValueSet && !removeClearButton"
        @mousedown.prevent.stop="onClickClearFilter(props)"
      >
        <b-icon-x />
      </div>
    </template>
    <template #tag="{ option, search, remove }">
      <slot name="tag" :option="option" :search="search" :remove="remove" />
    </template>
    <template #option="{ option, search }">
      <slot name="option" :option="option" :search="search" />
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';
export default {
  components: {
    Multiselect,
  },
  props: {
    isSelectionLabelHidden: { type: Boolean },
    placeholder: { type: String },
    removeClearButton: { type: Boolean, default: false },
  },
  computed: {
    isValueSet() {
      if (this?.$attrs?.value && Array.isArray(this.$attrs.value)) {
        return this.$attrs.value.length > 0;
      }
      return this.$attrs.value;
    },
  },
  methods: {
    onClickClearFilter(props) {
      if (this.$attrs.multiple) {
        this.$emit('input', []);
      } else {
        this.$emit('input', null);
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
