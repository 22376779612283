<template>
  <div>
    <h5
      role="button"
      @click="isCollapsed = !isCollapsed"
      class="user-select-none"
      :aria-expanded="isCollapsed ? 'true' : 'false'"
      aria-controls="collapse"
    >
      <b-icon v-if="!isCollapsed" :scale="0.75" icon="chevron-right" aria-hidden="true"></b-icon>
      <b-icon v-else :scale="0.75" icon="chevron-down" aria-hidden="true"></b-icon>
      {{ header }}
    </h5>
    <hr class="w-75 ml-0" />
    <b-collapse id="collapse" :class="isCollapsed ? 'collapsed' : null" v-model="isCollapsed">
      <div v-for="item in value" :key="'reisetermin' + item.label">
        <template v-if="!item?.notShow">
          <b-list-group horizontal class="bv-example-row">
            <b-list-group-item
              v-if="!item.split && !item?.splitN"
              class="col-6 d-flex align-items-center detailLabelGreyedOut"
              style=""
            >
              <h6 class="my-1 leftLabel" style="user-select: none">
                {{ item.label }}
                <i
                  v-if="item?.valueDiffersSeason"
                  class="fas fa-asterisk text-warning icon-sm"
                  style="align-self: start; position: absolute"
                  v-b-tooltip.hover
                  :title="`Saisonwert: ${item?.valueSeason}${item?.append ?? ''}`"
                ></i>
              </h6>
            </b-list-group-item>
            <!-- RIGHT SPLIT N TIMES (nur Releasefrist) -->
            <template v-if="item?.splitN">
              <b-list-group-item class="col-6 d-flex align-items-center detailLabelGreyedOut">
                <h6 class="my-1 leftLabel" style="user-select: none">
                  {{ item.label }}
                  <i
                    v-if="item?.valueDiffersSeason.some(item => item)"
                    class="fas fa-asterisk text-warning icon-sm"
                    style="align-self: start; position: absolute"
                    v-b-tooltip.hover
                    :title="`Saisonwert: ${item?.valueSeason}${item?.append ?? ''}`"
                  ></i>
                </h6>
              </b-list-group-item>
              <b-list-group-item
                class="col-6"
                style="display: grid; align-content: center; gap: 2.5rem"
                :style="{ gridTemplateColumns: `repeat(${item.value.length}, 1fr)` }"
                :class="{
                  changed: item.value.map((itemI, i) => itemI != item.valueEdited[i]).some(item => item),
                }"
              >
                <b-form-input
                  v-for="index in Array.from({ length: item.value.length }, (v, i) => i)"
                  v-model="item.valueEdited[index]"
                  :key="'splitN-' + index"
                  class="p-2 leftLabel"
                  type="number"
                  step="1"
                  min="0.00"
                  :number="true"
                  placeholder="-"
                ></b-form-input>
              </b-list-group-item>
            </template>

            <template v-else-if="item.split">
              <b-list-group-item class="col-6 d-flex align-items-center detailLabelGreyedOut" style="">
                <h6 class="my-1 leftLabel" style="user-select: none">
                  <span>
                    {{ item.label[0] }}
                    <i
                      v-if="item?.valueDiffersSeason?.[0]"
                      class="fas fa-asterisk text-warning icon-sm"
                      style="align-self: start; position: absolute"
                      v-b-tooltip.hover
                      :title="`Saisonwert: ${item?.valueSeason?.[0]}${item?.append?.[0] ?? ''}`"
                    ></i>
                  </span>
                  <span :class="{ 'ml-3': item?.valueDiffersSeason?.[0] }">/</span>
                  <span>
                    {{ item.label[1] }}
                    <i
                      v-if="item?.valueDiffersSeason?.[1]"
                      class="fas fa-asterisk text-warning icon-sm"
                      style="align-self: start; position: absolute"
                      v-b-tooltip.hover
                      :title="`Saisonwert: ${item?.valueSeason?.[1]}${item?.append?.[1] ?? ''}`"
                    ></i>
                  </span>
                </h6>
              </b-list-group-item>
              <b-list-group-item
                :class="{
                  detailLabelGreyedOut: !item.editable[0],
                  changed:
                    item.editable[0] &&
                    item.value[0] != item.valueEdited[0] &&
                    !arraysEqualUnordered(item.value[0], item.valueEdited[0]),
                }"
                class="col-3 d-flex align-items-center"
              >
                <b-form-input
                  v-model="item.valueEdited[0]"
                  class="p-2 leftLabel"
                  v-if="item.editable[0] && item.editType[0] === 'input'"
                  placeholder="-"
                ></b-form-input>
                <b-form-select
                  v-else-if="item.editable[0] && item.editType[0] === 'select'"
                  v-model="item.valueEdited[0]"
                  class="leftLabel"
                  :options="item.options"
                ></b-form-select>
                <b-form-checkbox
                  v-else-if="item.editable[0] && item.editType[0] === 'checkbox'"
                  size="lg"
                  class=""
                  v-model="item.valueEdited[0]"
                ></b-form-checkbox>
                <AirportsSucheMultiselect
                  v-else-if="item.editable[0] && item.editType[0] === 'flughafen'"
                  class="w-100"
                  style="height: 32px"
                  v-model="item.valueEdited[0]"
                  :isMultipleAllowed="true"
                  :openDirection="'top'"
                  :filterCountry="country"
                ></AirportsSucheMultiselect>
                <DatePicker
                  v-else-if="item.editable[0] && item.editType[0] === 'time'"
                  class="w-100"
                  v-model="item.valueEdited[0]"
                  type="time"
                  input-class="form-control"
                  format="HH:mm"
                  value-type="format"
                  ref="datepicker"
                />
                <div
                  v-else-if="item.editable[0] && item.editType[0] === 'number'"
                  class="d-flex leftLabel w-100"
                  style="align-items: center"
                >
                  <div v-if="item?.prepend" class="mr-1">{{ item.prepend }}</div>
                  <b-form-input
                    v-model="item.valueEdited[0]"
                    class="p-2 leftLabel"
                    type="number"
                    step="1"
                    min="0.00"
                    :number="true"
                    placeholder="-"
                  ></b-form-input>
                  <span v-if="item?.append?.[0]" class="ml-1">{{ item.append[0] }}</span>
                </div>
                <span v-else-if="item.renderer !== null">
                  <span v-html="item.renderer"></span>
                </span>
                <h6 class="my-1 leftLabel" v-else>
                  {{ item.value[0] }}
                  <span v-if="item?.append?.[0]" class="ml-1">{{ item.append[0] }}</span>
                </h6>
              </b-list-group-item>
              <b-list-group-item
                :class="{
                  detailLabelGreyedOut: !item.editable[1],
                  changed: item.editable[1] && item.value[1] != item.valueEdited[1],
                }"
                class="col-3 d-flex align-items-center"
              >
                <b-form-input
                  v-if="item.editable[1] && item.editType[1] === 'input'"
                  v-model="item.valueEdited[1]"
                  class="p-2 leftLabel"
                  placeholder="-"
                ></b-form-input>
                <b-form-select
                  v-else-if="
                    item.editable[1] && item.editType[1] === 'select' && item.editType[0] !== 'flughafen'
                  "
                  v-model="item.valueEdited[1]"
                  class="leftLabel"
                  :options="item.options"
                ></b-form-select>
                <DatePicker
                  v-else-if="item.editable[1] && item.editType[1] === 'time'"
                  class="w-100"
                  v-model="item.valueEdited[1]"
                  type="time"
                  input-class="form-control"
                  format="HH:mm"
                  value-type="format"
                  ref="datepicker"
                />
                <b-form-select
                  v-else-if="
                    item.editable[1] && item.editType[1] === 'select' && item.editType[0] === 'flughafen'
                  "
                  v-model="item.valueEdited[1]"
                  class="leftLabel"
                  :options="item.valueEdited[0]"
                ></b-form-select>
                <b-form-checkbox
                  v-else-if="item.editable[1] && item.editType[1] === 'checkbox'"
                  size="lg"
                  v-model="item.valueEdited[1]"
                ></b-form-checkbox>

                <div
                  v-else-if="item.editable[1] && item.editType[1] === 'number'"
                  class="d-flex leftLabel w-100"
                  style="align-items: center"
                >
                  <div v-if="item?.prepend" class="mr-1">{{ item.prepend }}</div>
                  <b-form-input
                    v-model="item.valueEdited[1]"
                    class="p-2 leftLabel w-100"
                    type="number"
                    step="1"
                    min="0.00"
                    :number="true"
                    placeholder="-"
                  ></b-form-input>
                  <span v-if="item?.append?.[1]" class="ml-1">{{ item.append[1] }}</span>
                </div>
                <span v-else-if="item.renderer !== null">
                  <span v-html="item.renderer"></span>
                </span>
                <h6 class="my-1 leftLabel" v-else>
                  {{ item.value[1] }}
                  <span v-if="item?.append?.[1]" class="ml-1">{{ item.append[1] }}</span>
                </h6>
              </b-list-group-item>
            </template>
            <b-list-group-item
              v-else
              class="col-6 d-flex align-items-center"
              :class="{
                detailLabelGreyedOut: !item.editable,
                changed: item.editable && item.value != item.valueEdited,
              }"
            >
              <b-form-input
                v-model="item.valueEdited"
                class="p-2 leftLabel"
                v-if="item.editable && item.editType === 'input'"
                placeholder="-"
                @input="$emit('input', value)"
              ></b-form-input>
              <div v-else-if="item.editType === 'checkbox'" class="d-flex align-items-center">
                <b-form-checkbox
                  :disabled="!item.editable"
                  size="lg"
                  v-model="item.valueEdited"
                ></b-form-checkbox>
                <span v-if="item?.append" class="ml-1 leftLabel">{{ item.append }}</span>
              </div>
              <div
                v-else-if="item.editable && item.editType === 'number'"
                class="d-flex leftLabel w-50"
                style="align-items: center"
              >
                <div class="mr-1" v-if="item?.prepend">{{ item.prepend }}</div>
                <b-form-input
                  v-model="item.valueEdited"
                  class="p-2 leftLabel"
                  type="number"
                  step="1"
                  min="0.00"
                  :number="true"
                  placeholder="-"
                ></b-form-input>
                <div class="ml-1" v-if="item?.append">{{ item.append }}</div>
              </div>
              <b-form-select
                v-else-if="item.editable && item.editType === 'select'"
                v-model="item.valueEdited"
                class="leftLabel"
                :options="item.options"
              ></b-form-select>
              <AirportsSucheMultiselect
                v-else-if="item.editable && item.editType === 'flughafen'"
                class="w-100"
                style="height: 32px"
                v-model="item.valueEdited"
                :isMultipleAllowed="false"
                :openDirection="'top'"
                :filterCountry="country"
              ></AirportsSucheMultiselect>
              <span v-else-if="item.renderer !== null">
                <span v-if="item.renderer === 'paxWidget'">
                  <PaxWidget
                    class="mb-2"
                    :paxObject="item.value?.pax"
                    :kalkPax="item.value?.metadata?.kalkPax"
                    :erwartetePax="item.value?.metadata.erwartetePax"
                    :durchfuehrbareMinPax="item.value?.metadata.durchfuehrbareMinPax"
                  ></PaxWidget>
                </span>
                <span v-else v-html="item.renderer"></span>
              </span>
              <h6 class="my-1 leftLabel" v-else>
                {{ item.value }}
                <span v-if="item?.append" class="ml-1">{{ item.append }}</span>
              </h6>
            </b-list-group-item>
          </b-list-group>
        </template>
      </div>
    </b-collapse>
  </div>
</template>
<script>
import PaxWidget from '@/components/produkte/reisetermine/pax-widget.vue';
import AirportsSucheMultiselect from '@/components/common/airports-suche-multiselect.vue';
import { arraysEqualUnordered } from '@/core/common/helpers/utils';
import DatePicker from 'vue2-datepicker';

export default {
  props: {
    isCollapsedOnMount: { type: Boolean, default: null },
    value: { type: Array },
    header: { type: String, required: true },
    country: { type: String },
  },
  components: { PaxWidget, AirportsSucheMultiselect, DatePicker },
  data() {
    return {
      isCollapsed: false,
    };
  },
  watch: {
    value: {
      handler(v) {
        this.value = v;
      },
      deep: true,
    },
  },
  mounted() {
    this.isCollapsed = this.isCollapsedOnMount ?? this.isCollapsed;
  },
  methods: { arraysEqualUnordered },
};
</script>
<style scoped>
.normalFormatted {
  border-style: solid;
  border-color: rgba(114, 114, 114, 0.15);
}
.detailLabelGreyedOut {
  background-color: rgba(114, 114, 114, 0.05);
  border-style: solid;
  border-color: rgba(114, 114, 114, 0.15);
}
.leftLabel {
  font-size: 1.05rem;
  font-weight: 500;
}
:deep(.form-control) {
  height: 32px !important;
}
.list-group {
  height: 42px;
}
:deep(.custom-select) {
  height: 32px !important;
  padding: 0.1rem 0.5rem;
}
:deep(.multiselect__tags) {
  height: 32px !important;
  font-size: 1.05rem;
}
:deep(.multiselect__single) {
  font-size: 1.05rem;
  font-weight: 500;
}
.changed {
  background-color: #3699ff50;
}
.tooltip {
  opacity: 1 !important;
}
:deep(.custom-tooltip) > .tooltip-inner {
  /* Removes the default max-width */
  max-width: none;

  /* Apply whatever other styles you want */
}
</style>
