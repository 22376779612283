<template>
  <b-button-group :size="size" class="d-flex" :style="groupStyles">
    <b-button
      v-for="regionsmanager in regionsmanagers"
      :key="regionsmanager"
      :id="regionsmanager"
      :variant="
        isRegionsmanagersSelected(regionsmanager)
          ? 'primary'
          : value.length === 0 && regionsmanager === 'Alle'
          ? 'primary'
          : ''
      "
      @click="regionsmanagerClicked(regionsmanager)"
    >
      {{ regionsmanager }}
    </b-button>
  </b-button-group>
</template>

<script>
export default {
  name: 'RegionsmanagerButtonGroup',
  props: {
    regionsmanagers: {
      type: Array,
      required: true,
      validator: value => value.length > 0,
    },
    size: {
      type: String,
      default: 'sm',
    },
    gap: {
      type: [String, Number],
      default: '2px',
    },
    value: {
      type: Array,
      required: true,
    },
  },
  computed: {
    groupStyles() {
      return {
        gap: typeof this.gap === 'number' ? `${this.gap}px` : this.gap,
      };
    },
    availableRegionsmanagers() {
      return this.regionsmanagers.filter(item => item !== 'Alle');
    },
  },
  methods: {
    isRegionsmanagersSelected(regionsmanager) {
      return this.value.includes(regionsmanager);
    },

    regionsmanagerClicked(regionsmanager) {
      //this. value ist die aktuelle auswahl, weil man auch mehrere auswählen kann
      let newSelectedRegionsmanagers = [...this.value];

      if (regionsmanager === 'Alle') {
        newSelectedRegionsmanagers = [];
      } else {
        if (newSelectedRegionsmanagers.includes(regionsmanager)) {
          newSelectedRegionsmanagers = this.value.filter(item => item !== regionsmanager);
        } else {
          newSelectedRegionsmanagers.push(regionsmanager);
        }

        if (newSelectedRegionsmanagers.length === this.regionsmanagers.filter(r => r !== 'Alle').length) {
          newSelectedRegionsmanagers = [];
        }
      }
      this.$emit('input', newSelectedRegionsmanagers);
    },
  },
};
</script>
